import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { BookingItemTransmitted, LoxoneCredentials, TenantCustomizationInfo } from "../../utils/types";
import { CollapsibleAccessCode, CollapsibleLoxoneCredentials } from "../Registration/Regform";
import { DEFAULT_APP_LOGO_URL } from "../../constant/app.constant";
import { getTenantCustomizationInfoByTechId } from "../../service/tenantCustomization";

const ItemCard = ({
  bookingItem,
  backendBaseUrl,
  accessCodes,
  setAccessCodes,
  directionLink,
  propertiesControl,
  loxoneCredentials
}: {
  bookingItem: BookingItemTransmitted;
  backendBaseUrl: string;
  accessCodes: Map<string, string>;
  setAccessCodes: (...args: any[]) => any;
  directionLink: string;
  propertiesControl: Map<string, boolean>;
  loxoneCredentials: Map<string, LoxoneCredentials>;
}) => {
  const checkInTime = new Date(bookingItem.checkInTime._seconds! * 1000).toLocaleDateString();
  const checkOutTime = new Date(bookingItem.checkOutTime._seconds * 1000).toLocaleDateString();
  const [tenantCustomizationInfo, setTenantCustomizationInfo] = useState<TenantCustomizationInfo>();

  useEffect(() => {
    if (!tenantCustomizationInfo) {
      getTenantCustomizationInfoByTechId(backendBaseUrl, bookingItem.propertyTechId).then(result => {
        setTenantCustomizationInfo(result);
      });
    }
  }, []);

  return (
    <>
      <div className="guest-data">
        <div className="booking-info">
          <a href="https://jobelhome.com">
            <img
              className="success-logo"
              src={tenantCustomizationInfo?.logoUrl ?? DEFAULT_APP_LOGO_URL}
              onError={event => {
                event.currentTarget.src = DEFAULT_APP_LOGO_URL;
                event.currentTarget.onerror = null;
              }}
              alt="Jobelhome logo"
            />
          </a>
          <p className="dashboard-guest-data">
            Name: {bookingItem.guests[0].firstName} {bookingItem.guests[0].lastName}
          </p>
          <p className="dashboard-guest-data">
            Booked dates: <CalendarOutlined style={{ fontSize: 20 }} />
            {checkInTime}-{checkOutTime}
          </p>
          <p className="dashboard-guest-data">
            Number of guests:
            <div className="icons-div">
              {Array.from({ length: bookingItem.guestNumber }, (_, i) => (
                <span className="array-span" key={i}>
                  <UserOutlined style={{ fontSize: 20 }} />
                </span>
              ))}
            </div>
            {bookingItem.guestNumber}
          </p>
          <p className="dashboard-guest-data">Booked Apartment: {bookingItem.propertyName}</p>

          {/*{accessesModule(*/}
          {/*    bookingItem.propertyName,*/}
          {/*    bookingItem.itemBookingId,*/}
          {/*    backendBaseUrl,*/}
          {/*    accessCodes,*/}
          {/*    setAccessCodes*/}
          {/*)}*/}

          {accessCodeModule(
            bookingItem.propertyName,
            bookingItem.itemBookingId,
            setAccessCodes,
            accessCodes,
            backendBaseUrl
          )}

          {loxoneDetailModule(
            loxoneCredentials,
            bookingItem.itemBookingId,
            propertiesControl.get(bookingItem.propertyId)!
          )}

          <div className="dashboard-direction-div">
            <a href={directionLink} target="_blank" rel="noreferrer">
              <button type="button" className="direction-btn">
                Your direction
              </button>
            </a>
          </div>
          <div className="dashboard-direction-div">
            <p className="dashboard-guest-data">Reference number: #{bookingItem.itemBookingId}</p>
          </div>
        </div>
      </div>
    </>
  );
};

function accessCodeModule(
  propertyName: string,
  bookingId: string,
  setAccessCodes: (...args: any[]) => any,
  accessCodes: Map<string, string>,
  backendUrl: string
) {
  return (
    <CollapsibleAccessCode
      propertyname={propertyName}
      bookingId={bookingId}
      setAccessCodes={setAccessCodes}
      accessCodes={accessCodes}
      backendUrl={backendUrl}
    />
  );
}

function loxoneDetailModule(
  loxoneCredentials: Map<string, LoxoneCredentials>,
  bookingId: string,
  isLoxoneProperty: boolean
) {
  const loxoneCredential = loxoneCredentials.get(bookingId) as LoxoneCredentials;

  let url = "";
  let username = "";
  let password = "";

  if (loxoneCredential !== undefined) {
    if (loxoneCredential.url !== undefined) url = loxoneCredential.url;
    if (loxoneCredential.username !== undefined) username = loxoneCredential.username;
    if (loxoneCredential.password !== undefined) password = loxoneCredential.password;
  }

  if (isLoxoneProperty) {
    return <CollapsibleLoxoneCredentials url={url} username={username} password={password} />;
  } else {
    return;
  }
}

export default ItemCard;
