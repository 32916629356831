import countries from "i18n-iso-countries";
import React, { FC } from "react";
import "./InvoiceAddressStyle.css";

type Props = {
  differentInvoiceAddressRequired: any;
  setDifferentInvoiceAddressRequired: any;
  invoiceAddress: any;
  setInvoiceAddress: any;
};

const InvoiceAddress: FC<Props> = ({
  differentInvoiceAddressRequired,
  setDifferentInvoiceAddressRequired,
  invoiceAddress,
  setInvoiceAddress
}) => {
  const changeInvoiceAddress = (e: any, source: string) => {
    const invoiceAddressCopy = { ...invoiceAddress };
    invoiceAddressCopy[source] = e;
    setInvoiceAddress(invoiceAddressCopy);
  };

  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key
    };
  });
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });
  return (
    <>
      <div className="different-invoice-wrapper">
        <label className="diff-invoice-text" htmlFor="differentInvoiceAddress">
          Different Invoice Address
        </label>
        {differentInvoiceAddressRequired ? (
          <input
            type="checkbox"
            name="differentInvoiceAddress"
            value="true"
            onChange={() => setDifferentInvoiceAddressRequired(false)}
          />
        ) : (
          <input
            type="checkbox"
            name="differentInvoiceAddress"
            onChange={() => setDifferentInvoiceAddressRequired(true)}
          />
        )}

        {differentInvoiceAddressRequired && (
          <div className="invoice-address-inputs">
            <span className="inline">
              <label>
                <p>Company name</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="Company name"
                  value={invoiceAddress.companyName}
                  onChange={e => changeInvoiceAddress(e.target.value, "companyName")}
                />
              </label>
              <label>
                <p>First name*</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="First name"
                  value={invoiceAddress.firstName}
                  onChange={e => changeInvoiceAddress(e.target.value, "firstName")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>Last name*</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="Last name"
                  value={invoiceAddress.lastName}
                  onChange={e => changeInvoiceAddress(e.target.value, "lastName")}
                />
              </label>
              <label>
                <p>Tax number</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="Tax number"
                  value={invoiceAddress.taxNumber}
                  onChange={e => changeInvoiceAddress(e.target.value, "taxNumber")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>Address*</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="Address"
                  value={invoiceAddress.address}
                  onChange={e => changeInvoiceAddress(e.target.value, "address")}
                />
              </label>
              <label>
                <p>Postalcode*</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="Postalcode"
                  value={invoiceAddress.postalCode}
                  onChange={e => changeInvoiceAddress(e.target.value, "postalCode")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>City</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder="City"
                  value={invoiceAddress.city}
                  onChange={e => changeInvoiceAddress(e.target.value, "city")}
                />
              </label>
              <label>
                <p>Country*</p>
                <select
                  className="dropDown"
                  value={invoiceAddress.country}
                  onChange={e => changeInvoiceAddress(e.target.value, "country")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
          </div>
        )}
      </div>
    </>
  );
};
export default InvoiceAddress;
