import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BookingItemTransmitted, TenantCustomizationInfo } from "../../utils/types";
import "./PropertyStyle.css";
import { CollapsibleGuests } from "./Regform";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_APP_LOGO_URL,
  DEFAULT_APP_PRIVACY_PAGE_URL,
  DEFAULT_APP_PROPERTY_RULES_PAGE_URL
} from "../../constant/app.constant";
import { getTenantCustomizationInfoByTechId } from "../../service/tenantCustomization";

const PropertyDisplay = ({
  bookingItems,
  i,
  setBookingItems,
  propertyImages,
  backendBaseUrl
}: {
  i: number;
  bookingItems: BookingItemTransmitted[];
  setBookingItems: any;
  propertyImages: string[];
  backendBaseUrl: string;
}) => {
  const { t } = useTranslation();
  const bookingItem: BookingItemTransmitted = bookingItems[i];
  const [tenantCustomizationInfo, setTenantCustomizationInfo] = useState<TenantCustomizationInfo>();

  useEffect(() => {
    if (!tenantCustomizationInfo) {
      getTenantCustomizationInfoByTechId(backendBaseUrl, bookingItem.propertyTechId).then(result => {
        setTenantCustomizationInfo(result);
      });
    }
  }, []);

  return (
    <div className="property-wrapper">
      <div className="property-card">
        <Carousel
          className="carousel-main"
          width={350}
          transitionTime={1}
          swipeable={true}
          stopOnHover={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          emulateTouch={true}
          interval={4000}
          showArrows={true}
          showStatus={false}
          showIndicators={true}
        >
          {propertyImages &&
            propertyImages.map((url: string) => (
              <div className="carousel-item">
                <img src={url} alt="Property" />
              </div>
            ))}
        </Carousel>
      </div>
      <section className="inline-data">
        <HomeOutlined className="property-icon" style={{ fontSize: 20 }} />
        <h5 className="h5-reg-info">{bookingItem.propertyName}</h5>
        {Array.from({ length: bookingItem.guestNumber }, (_, i) => (
          <span key={i}>
            <UserOutlined style={{ fontSize: 20 }} />
          </span>
        ))}
        <p className="guestnumber-details">{bookingItem.guestNumber}</p>
      </section>
      <div className="header-data">
        <img
          className="logo"
          src={tenantCustomizationInfo?.logoUrl ?? DEFAULT_APP_LOGO_URL}
          onError={event => {
            event.currentTarget.src = DEFAULT_APP_LOGO_URL;
            event.currentTarget.onerror = null;
          }}
          alt="NOTHING"
        />
        <p className="regpage-welcome">
          {t("registration.welcome-first-part")} {bookingItems[0] ? bookingItems[0].guests[0].firstName : ""},
          <br />
          {t("registration.welcome-second-part")}{" "}
          {tenantCustomizationInfo?.propertyDisplayName ?? "JobelHome Apartments!"}
          <br />
        </p>
        {/*<section className="inline-data">*/}
        {/*  {Array.from({ length: guestNumber }, (_, i) => (*/}
        {/*    <span key={i}>*/}
        {/*        <UserOutlined style={{ fontSize: 20 }} />*/}
        {/*      </span>*/}
        {/*  ))}*/}
        {/*  <p className="guestnumber-details">{guestNumber}</p>*/}
        {/*</section>*/}
        <div>
          {bookingItem.checkInTime ? new Date(bookingItem.checkInTime._seconds * 1000).toLocaleDateString() : ""}-
          {bookingItem.checkOutTime ? new Date(bookingItem.checkOutTime._seconds * 1000).toLocaleDateString() : ""}
        </div>
      </div>
      {[...Array(bookingItem.guests.length).keys()].map(j => (
        <CollapsibleGuests key={j} bookingItems={bookingItems} i={i} j={j} setBookingItems={setBookingItems} />
      ))}
      <div className="privacy-info">
        <p className="apartment-rules-p">
          {t("registration.privacy-first-part")} <br />
          <a
            className="inline-link"
            href={tenantCustomizationInfo?.privacyPolicyUrl ?? DEFAULT_APP_PRIVACY_PAGE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("registration.privacy-second-part")}
          </a>
        </p>
        <p className="apartment-rules-p">
          {t("registration.house-rules-first-part")} <br />
          <a
            className="inline-link"
            href={tenantCustomizationInfo?.propertyRulesUrl ?? DEFAULT_APP_PROPERTY_RULES_PAGE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("registration.house-rules-second-part")}
          </a>
          {t("registration.house-rules-third-part")}
        </p>
      </div>
    </div>
  );
};

export default PropertyDisplay;
