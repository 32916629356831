import axios from "axios";
import { GetBookingAccessResult } from "../utils/types";

export async function findAccessByBookingId(backendUrl: string, bookingId: string): Promise<any> {
  const token: string = localStorage.getItem("token")!;

  try {
    const config = {
      method: "post",
      url: `${backendUrl}getAccessCode`,
      headers: { "Content-Type": "application/json", Authorization: token },
      data: {
        bookingId: bookingId
      }
    };
    const response = await axios(config);
    const getAccessCodeResult: GetBookingAccessResult = response.data;
    if (getAccessCodeResult.severity === "normal") {
      return getAccessCodeResult;
    } else if (getAccessCodeResult.severity === "error") {
      alert(`Error: ${getAccessCodeResult.error?.toString()}`);
    } else if (getAccessCodeResult.severity === "warning") {
      alert(`Warning: ${getAccessCodeResult.error}`);
    } else {
      console.error("should not be possible, there is a problem");
      alert(getAccessCodeResult);
    }
  } catch (error: any) {
    console.log(error);
    alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
  }

  return null;
}
