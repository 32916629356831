import { DownOutlined, FileTextOutlined } from "@ant-design/icons";
import axios from "axios";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import React, { useState } from "react";
import useCollapse from "react-collapsed";
import { useTranslation } from "react-i18next";
import { GetAccessCodeResult } from "../../utils/types";
import "./RegformStyle.css";

const CollapsibleGuests = ({ i, j, bookingItems, setBookingItems }: any) => {
  const { t } = useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const guest = bookingItems[i].guests[j];
  const show = bookingItems[i].show[0];
  const isFamilyMember = bookingItems[i].guests[j].isFamilyMember;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const changeGuest = (e: any, source: string) => {
    const bookingItemsCopy = [...bookingItems];
    if ("isFamilyMember" === source) bookingItemsCopy[i].guests[j][source] = !bookingItemsCopy[i].guests[j][source];
    else bookingItemsCopy[i].guests[j][source] = e;
    setBookingItems(bookingItemsCopy);
  };
  return (
    <div className="collapsible">
      <div className="form-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "" : <FileTextOutlined style={{ fontSize: 30 }} />}
        {isExpanded ? <DownOutlined className="arrow" /> : ""}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <form id="guest-reg-form" className="regformWrapper">
            <span className="inline">
              <label>
                <p>{t("registration.fname")}*</p>
                <input
                  className="inputField"
                  type="text"
                  required
                  placeholder={t("registration.fname")}
                  value={guest.firstName}
                  onChange={e => changeGuest(e.target.value, "firstName")}
                />
              </label>
              {show && show.isLastNameRequired ? (
                <label>
                  <p>{t("registration.lname")}*</p>
                  <input
                    className="inputField"
                    type="text"
                    required
                    placeholder={t("registration.lname")}
                    value={guest.lastName}
                    onChange={e => changeGuest(e.target.value, "lastName")}
                  />
                </label>
              ) : (
                ""
              )}
            </span>
            <span className="inline">
              <label>
                <p>{t("registration.dateofbirth")}*</p>
                <input
                  className="inputField"
                  type="date"
                  required
                  placeholder={t("registration.dateofbirth")}
                  value={guest.dateOfBirth}
                  onChange={e => changeGuest(e.target.value, "dateOfBirth")}
                />
              </label>
              {show && show.isPlaceOfBirthRequired ? (
                <label>
                  <p>{t("registration.placeofbirth")}*</p>
                  <select
                    className="dropDown"
                    value={guest.placeOfBirth}
                    onChange={e => changeGuest(e.target.value, "placeOfBirth")}
                  >
                    {!!countryArr?.length &&
                      countryArr.map(({ label, value }: any) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                  </select>
                </label>
              ) : (
                ""
              )}
            </span>
            <span className="inline">
              {show && show.isGenderRequired ? (
                <label>
                  <p>{t("registration.gender")}*</p>
                  <select
                    className="dropDown"
                    name="gender"
                    value={guest.gender}
                    onChange={e => changeGuest(e.target.value, "gender")}
                  >
                    <option value="Def">{t("registration.choosegender")}</option>
                    <option value="Male">{t("registration.male")}</option>
                    <option value="Female">{t("registration.female")}</option>
                    <option value="Other">{t("registration.other")}</option>
                  </select>
                </label>
              ) : (
                ""
              )}
              {show && show.isNationalityRequired ? (
                <label>
                  <p>{t("registration.nationality")}*</p>
                  <select
                    className="dropDown"
                    value={guest.nationality}
                    onChange={e => changeGuest(e.target.value, "nationality")}
                  >
                    {!!countryArr?.length &&
                      countryArr.map(({ label, value }: any) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                  </select>
                </label>
              ) : (
                ""
              )}
            </span>
            {"isFamilyMember" in guest && show && show.isAddressRequired ? (
              <label>
                <p>{t("registration.familyMember")}*</p>
                <div className="inputField-familyMember-wrapper">
                  <input
                    type="checkbox"
                    id="familyMember"
                    name="familyMember"
                    onChange={e => changeGuest(e.target.value, "isFamilyMember")}
                    checked={guest.isFamilyMember}
                  ></input>
                </div>
              </label>
            ) : (
              ""
            )}
            {show && show.isAddressRequired ? (
              <span className="inline">
                <label>
                  <p>{t("registration.street")}*</p>
                  <input
                    className="inputField"
                    type="text"
                    required
                    disabled={isFamilyMember}
                    placeholder={t("registration.street")}
                    value={guest.address}
                    onChange={e => changeGuest(e.target.value, "address")}
                  />
                </label>
                <label>
                  <p>{t("registration.postalcode")}*</p>
                  <input
                    className="inputField"
                    type="text"
                    required
                    disabled={isFamilyMember}
                    placeholder={t("registration.postalcode")}
                    value={guest.postalCode}
                    onChange={e => changeGuest(e.target.value, "postalCode")}
                  />
                </label>
              </span>
            ) : (
              ""
            )}
            <span className="inline">
              {show && show.isAddressRequired ? (
                <label>
                  <p>{t("registration.city")}*</p>
                  <input
                    className="inputField"
                    type="text"
                    required
                    disabled={isFamilyMember}
                    placeholder={t("registration.city")}
                    value={guest.city}
                    onChange={e => changeGuest(e.target.value, "city")}
                  />
                </label>
              ) : (
                ""
              )}
              <label>
                <p>{t("registration.country")}*</p>
                <select
                  className="dropDown"
                  value={guest.country}
                  onChange={e => changeGuest(e.target.value, "country")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              {show && show.isTypeOfIdRequired ? (
                <label>
                  <p>{t("registration.id")}*</p>
                  <select
                    className="dropDown"
                    name="typeOfID"
                    value={guest.typeOfID}
                    onChange={e => changeGuest(e.target.value, "typeOfID")}
                  >
                    <option value="Def">{t("registration.Def")}</option>
                    <option value="idNumber">{t("registration.idNumberOpt")}</option>
                    <option value="passport">{t("registration.passportOpt")}</option>
                    <option value="drivingLicense">{t("registration.drivingLicenseOpt")}</option>
                  </select>
                </label>
              ) : (
                ""
              )}
              {show && show.isIdRequired ? (
                <label>
                  <p>{t("registration.passport")}*</p>
                  <input
                    className="inputField"
                    type="text"
                    required
                    placeholder={t("registration.passport")}
                    value={guest.idNumber}
                    onChange={e => changeGuest(e.target.value, "idNumber")}
                  />
                </label>
              ) : (
                ""
              )}
            </span>
            {show && show.isEmailRequired ? (
              <label>
                <p>
                  {t("registration.email")}
                  {i + j === 0 && "*"}
                </p>
                <input
                  className="inputField-email"
                  type="email"
                  required
                  placeholder={t("registration.email")}
                  value={guest.email}
                  onChange={e => changeGuest(e.target.value, "email")}
                />
              </label>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const CollapsibleAccessCode = ({ propertyname, bookingId, setAccessCodes, accessCodes, backendUrl }: any) => {
  const [accessModuleState, setAccessModuleState] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  const expand = () => {
    setAccessModuleState(true);
  };

  const collapse = () => {
    setAccessModuleState(false);
  };

  const handleClick = async () => {
    await callGetAccessCode();
    if (accessModuleState) {
      expand();
    } else {
      collapse();
    }
  };

  const callGetAccessCode = async () => {
    try {
      setTimeout(() => {
        setIsLoading(true);
      }, 0);

      const config = {
        method: "post",
        url: `${backendUrl}getAccessCode`,
        headers: { "Content-Type": "application/json" },
        data: {
          bookingId
        }
      };
      const response = await axios(config);
      const getAccessCodeResult: GetAccessCodeResult = response.data;
      if (getAccessCodeResult.severity === "normal") {
        const newAccessCodes = new Map(accessCodes);
        newAccessCodes.set(bookingId, getAccessCodeResult.accessCode!);
        setAccessCodes(newAccessCodes);
      } else if (getAccessCodeResult.severity === "error") {
        alert(`Error: ${getAccessCodeResult.error?.toString()}`);
      } else if (getAccessCodeResult.severity === "warning") {
        alert(`Warning: ${getAccessCodeResult.error}`);
      } else {
        console.error("should not be possible, there is a problem");
        alert(getAccessCodeResult);
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    } finally {
      setIsLoading(false); // Disable loading when done
    }
  };

  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps({ onClick: handleClick })}>
        {isExpanded ? "Hide Entry Code" : ""}
        {isExpanded ? "" : "Entry Code"}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        {isLoading && (
          <div className="loading-overlay">
            <p className="loading-text">Loading...</p>
          </div>
        )}
        <div className="content">
          <p className="dashboard-guest-loxone-data">The access code for entering apartment</p>
          <p className="dashboard-guest-loxone-data">
            {propertyname} Entry Code: {accessCodes.get(bookingId)}
          </p>
        </div>
      </div>
    </div>
  );
};

const CollapsibleLoxoneCredentials = ({ url, username, password }: any) => {
  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "Hide Information" : ""}
        {isExpanded ? "" : "Control Apartment"}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <p className="dashboard-guest-loxone-data">Control your apartment heating, lighting and ...</p>
          <p className="dashboard-guest-loxone-data">
            Dashboard:{" "}
            <a href={url} target="_blank" rel="noreferrer">
              Application URL
            </a>
          </p>
          <div className="loxone-credentials-grid">
            <p className="dashboard-guest-loxone-data">Username: {username}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(username);
              }}
            >
              Copy
            </button>
            <p className="dashboard-guest-loxone-data">Password: {password}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(password);
              }}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CollapsibleGuests, CollapsibleAccessCode, CollapsibleLoxoneCredentials };
