import { CheckCircleOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import axios from "axios";
import LZString from "lz-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import booked_room from "../../assets/booked_room.png";
import entry_code from "../../assets/entry_code.png";
import registration from "../../assets/registration.png";
import { BookingItemTransmitted, Guest, RegistrationPageData } from "../../utils/types";
import InvoiceAddress from "./InvoiceAddress";
import PropertyDisplay from "./PropertyDisplay";
import "./RegistrationPageStyle.css";

const RegistrationForm = () => {
  const { t } = useTranslation();
  const [submitActive] = useState(false);
  const [bookingItems, setBookingItems] = useState<Array<BookingItemTransmitted>>([]);
  const [bookingData, setBookingData] = useState<any>({});
  const [firestoreId, setFirestoreId] = useState<string>();
  const [differentInvoiceAddressRequired, setDifferentInvoiceAddressRequired] = useState(false);
  const [invoiceAddress, setInvoiceAddress] = useState({});
  const [propertyImages, setPropertyImages] = useState<Array<Array<string>>>([]);
  const [backendBaseUrl, setBackendBaseUrl] = useState<string>("");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const params: any = {};
  window.location.search
    .slice(1)
    .split("&")
    .forEach((pair: any) => {
      pair = pair.split("=");
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

  useEffect(() => {
    const getLocaleCountry = (bookingItems: BookingItemTransmitted[]) => {
      if (bookingItems[0] && !bookingItems[0].guests[0].country) {
        try {
          axios
            .get("https://geolocation-db.com/json/")
            .then((response: { data: { country_code: string } }) => {
              const countryCode = response.data.country_code;
              console.debug("Got country code from geolocation api %s", countryCode);
              bookingItems.forEach((element: BookingItemTransmitted, i: number) =>
                element.guests.forEach((guest: Guest, j: number) => {
                  if (!guest.nationality) bookingItems[i].guests[j].nationality = countryCode;
                  if (!guest.country) bookingItems[i].guests[j].country = countryCode;
                  if (!guest.placeOfBirth) bookingItems[i].guests[j].placeOfBirth = countryCode;
                })
              );
              setBookingItems(bookingItems);
            })
            .catch((error: any) => console.error(error));
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (bookingItems.length === 0) {
      const decommpressedData = LZString.decompressFromEncodedURIComponent(params.data);
      const parsedData: RegistrationPageData = JSON.parse(decommpressedData);
      setBookingData(parsedData.booking);
      setFirestoreId(parsedData.firestoreId);
      setInvoiceAddress(parsedData.booking.issuer);
      setDifferentInvoiceAddressRequired(parsedData.booking.differentInvoiceAddressRequired);
      setBackendBaseUrl(parsedData.backendBaseUrl!);
      const newBookingItems = parsedData.bookingItems;
      newBookingItems.forEach((element: BookingItemTransmitted, index: number) => {
        //this can be removed in the future
        for (let i = 1; i < element.guestNumber; i++)
          if (!element.guests[i])
            newBookingItems[index].guests = [
              ...newBookingItems[index].guests,
              {
                firstName: null,
                lastName: null,
                address: null,
                postalCode: null,
                city: null,
                country: null,
                dateOfBirth: null,
                gender: null,
                nationality: null,
                placeOfBirth: null,
                typeOfID: null,
                email: null,
                idNumber: null
              }
            ];
      });
      setBookingItems(newBookingItems);
      getLocaleCountry(newBookingItems);
      newBookingItems.forEach(async (element: BookingItemTransmitted, index: number) => {
        const config = {
          method: "post",
          url: `${backendBaseUrl}propertyImg`,
          headers: {
            "Content-Type": "application/json"
          },
          data: JSON.stringify({ propertyId: element.propertyId })
        };
        try {
          const propertyImageRes = await axios(config);
          const propertyImage = propertyImageRes.data;
          const newPropertyImages = [...propertyImages];
          newPropertyImages.push(propertyImage);
          setPropertyImages(newPropertyImages);
        } catch (error) {
          console.error(error);
        }
      });
    }
  }, [bookingItems.length, params.data, propertyImages, backendBaseUrl]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (submitLoading)
      return;

    setSubmitLoading(true);
    const config = {
      method: "post",
      url: `${backendBaseUrl}registrate`,
      headers: { access: "" },
      data: {
        items: bookingItems,
        firestoreId,
        invoiceAddress,
        differentInvoiceAddressRequired
      }
    };

    if (params.access === "service") config.headers.access = params.access;

    try {
      const response = await axios(config);

      if (response?.status == 201 && response?.data?.url) {
        window.location.href = response.data.url;
      } else {
        alert("Failed to redirect to portal");
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.data?.message) {
        alert(error.response.data.message);
      } else if (error.response?.status === 302) {
        console.log("redirect", error);
        window.location.href = error.response.headers.location;
      } else {
        alert("Unexpected error occurred");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="regformWrapper">
        <div className="header-data">
          <p className="secondary-info">#{bookingData.masterBookingId}</p>
        </div>
        <div className="main-icons">
          <div className="icon-wrap">
            <p>
              1. step <CheckCircleOutlined />
            </p>
            <img
              width={60}
              height={60}
              id="first-step"
              data-tooltip-content="You have booked your apartment successfully."
              className="icon-background"
              src={booked_room}
              alt=""
            />
            <Tooltip data-tooltip-id="first-step" />
          </div>
          <div className="icon-wrap">
            <p className="icon-background-step2-number">2. step</p>
            <img
              width={80}
              height={80}
              id="second-step"
              data-tooltip-content="You have to make your registration."
              className="icon-background-step2"
              src={registration}
              alt=""
            />
            <Tooltip data-tooltip-id="second-step" />
          </div>
          <div className="icon-wrap">
            <p>3. step</p>
            <img
              width={60}
              height={60}
              id="third-step"
              data-tooltip-content="After successful registration you will receive your entry codes."
              className="icon-background"
              src={entry_code}
              alt=""
            />
            <Tooltip data-tooltip-id="third-step" />
          </div>
        </div>
        <div className="properties-wrapper">
          {[...Array(bookingItems.length).keys()].map(i => (
            <PropertyDisplay
              key={i}
              bookingItems={bookingItems}
              i={i}
              setBookingItems={setBookingItems}
              propertyImages={propertyImages[i]}
              backendBaseUrl={backendBaseUrl}
            />
          ))}
        </div>
        <InvoiceAddress
          differentInvoiceAddressRequired={differentInvoiceAddressRequired}
          setDifferentInvoiceAddressRequired={setDifferentInvoiceAddressRequired}
          invoiceAddress={invoiceAddress}
          setInvoiceAddress={setInvoiceAddress}
        />
        <div className="button-container">
          <button
            form="guest-reg-form"
            className="submitButton"
            onClick={handleSubmit}
            type="submit"
            disabled={submitActive || submitLoading}
          >
            {t("registration.submit")}
            {
              submitLoading ? <LoadingOutlined className="submit-loading" /> : <SendOutlined className="button-icon-send" />
            }

          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
